.sessionHistoryInnerContainerEmptySession {
  /* padding: 130px 3vw 30px 3vw; */
  display: flex;
  width: 100%;
  height: 50vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sessionHistoryInnerContainerEmptySession img {
  object-fit: scale-down;
  width: 300px;
  height: auto;
}
