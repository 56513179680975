.fullscreenContainer {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentContainer {
  width: 65vw;
  max-height: 50vh;
  background-color: #ffffff;
  border-radius: 20px;
}

.contentFullScreen {
  margin: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentTitle {
  font-size: 34px;
  color: #00bb99;
  font-weight: 500;
}

.contentUploadingFileContainer {
  overflow: auto;
  width: 60vw;
  max-height: 30vh;
}

.eachContentContainer {
  margin-top: 2em;
  margin-left: 3em;
  margin-right: 3em;
  margin-bottom: 3em;
}

.contentHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
}

.noInternetContentContainer {
  width: 50vw;
  height: 70vh;
  background-color: #ffffff;
  overflow: auto;
  display: flex;
}


@media (max-width: 767px) {
  .fullscreenContainer {
    text-align: left;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .noInternetContentContainer {
    width: 100vw;
    height: 70vh;
    background-color: #ffffff;
    overflow: auto;
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .noInternetContentContainer {
    width: 70vw;
    height: 80vh;
    background-color: #ffffff;
    overflow: auto;
    display: flex;
  }
}
