.userFormHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.useFormHeaderInitialValueEmptyContainer {
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
}

.useFormHeaderInitialValueEmptyContainer img {
  width: 20px;
  height: auto;
  object-fit: contain;
}

.userFormBtnMainContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.userFormBtnSave {
  font-size: 14px;
  font-weight: bold;
  color: white;
  background: #00bb99;
  border: #00bb99;
  border-radius: 40px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  min-width: 125px;
  min-height: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userFormBtnSave:hover {
  background: #00bb99;
  cursor: pointer;
  opacity: 0.8;
}

.userFormBtnSaveDisabled {
  font-size: 14px;
  font-weight: bold;
  color: white;
  background: #00bb99;
  border: #00bb99;
  border-radius: 40px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  min-width: 125px;
  min-height: 30px;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userFormBtnSaveDisabled:hover {
  background: #00bb99;
  opacity: 0.5;
}

.userFormBtnSaveDisabledForStudent {
  font-size: 14px;
  font-weight: bold;
  color: white;
  background: #2c65f7;
  border: #2c65f7;
  border-radius: 40px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  min-width: 125px;
  min-height: 30px;
  opacity: 0.5;
}

.userFormBtnSaveDisabledForStudent:hover {
  background: #2c65f7;
  opacity: 0.5;
}

.userFormBtnSaveForStudent {
  font-size: 14px;
  font-weight: bold;
  color: white;
  background: #2c65f7;
  border: #2c65f7;
  border-radius: 40px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  min-width: 125px;
  min-height: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userFormBtnSaveForStudent:hover {
  background: #2c65f7;
  cursor: pointer;
  opacity: 0.8;
}

.userFormBtnCancel {
  font-size: 14px;
  font-weight: bold;
  color: white;
  background: #aaaaaa;
  border: #aaaaaa;
  border-radius: 40px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  min-width: 125px;
  min-height: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userFormBtnCancel:hover {
  background: #aaaaaa;
  cursor: pointer;
  opacity: 0.8;
}

.userFormRowMainContainer {
  --bs-gutter-x: 1.5rem;
}

.userFormRowMainContainerCourse {
  --bs-gutter-x: 3.5rem;
}

.userFormInputGroup {
  margin-bottom: 20px;
}

.userFormInputLabel {
  margin-bottom: 5px;
  font-weight: bold;
  display: flex;
  gap: 5px;
  align-items: center;
}

.userFormInputStyle {
  font-weight: "normal";
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
}

.userFormInputGroup .userFormInputGroupFlexContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  gap: 10px;
}

.inputPhoneBox {
  display: flex;
  gap: 1em;
}

.inputPhoneCode {
  width: 70px;
  height: 48px;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9e9e9e;
  background-color: #f5f5f5;
}

@media (max-width: 767px) {
  .userFormHeaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .userFormBtnMainContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
}
