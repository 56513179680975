.navbar {
  border-bottom: solid 1px #ebebeb;
  background-color: #ffffff;
}
.bottom-tab-nav {
  border-top: solid 1px #ebebeb;
}
.nav-link,
.bottom-nav-link {
  color: #000000;
}
.bottom-nav-link.active {
  /* background-color: #00f5f5; */
  border-radius: 15px;
  color: #000000;
}
.student-bottom-nav-link.active {
  background-color: #002b73;
  border-radius: 15px;
  color: #ffffff;
}
.bottom-tab-label {
  font-size: 1.3vh;
  font-weight: bold;
}
