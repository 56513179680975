.verifyAccountRegisteredTextStyle {
  font-size: 36px;
  font-weight: bold;
  color: #00bb99;
  text-align: center;
  margin: 0;
  margin-top: 36px;
}

.verifyAccountRegisteredSmallTextStyle {
  margin: 0;
  margin-top: 14px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  padding: 32px;
}

.verifyAccountRegisteredGetStartedBtn {
  margin-top: 44px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: #00bb99;
  border: #00bb99;
  border-radius: 40px;
  min-width: 160px;
  min-height: 40px;
  padding: 0.5rem 1rem;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  :hover {
    background: #00bb99;
    opacity: 0.8;
  }
}

.verifyAccountRegisteredLoadingText {
  font-size: 36px;
  color: #00bb99;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
