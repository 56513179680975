.inputImageContainer {
  width: "100%";
  height: 300px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 2px solid #00bb99;
}

.inputImageContainerNoPointer {
  width: "100%";
  height: 300px;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid #00bb99;
}

.imageToViewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageFileNameFontStyle {
  color: #798692;
  font-weight: 400;
  font-size: 13px;
}

.btnChangeImage {
  margin-left: 1.5em;
  width: 160px;
  height: 35px;
  background: #d7ffff;
  border: 1px solid #000000;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.changeImageLabel {
  margin-left: 0.75em;
  color: #007c7c;
  font-weight: 400;
  font-size: 14px;
}

.labelImage {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-top: 1.5em;
  color: #000000;
}

.placeholderImage {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  margin-top: 1em;
  color: #798692;
}

.labelForDescription {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.secondLabel {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-left: 1.5em;
  color: #007c7c;
}

.recommendedInputContainer {
  margin-top: 0.5em;
  background-color: #d7ffff;
  border-radius: 5px;
}

.recommendedInputBodyContainer {
  padding: 7.5px;
  gap: 0.5em;
  align-items: center;
  display: flex;
}

.recommendedInputContainer img {
  width: 20px;
  height: auto;
}

.recommendedInputBodyContainer div {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #007c7c;
}

.courseLessonMainContainer {
  padding-top: 10px;
  border-bottom: 2px solid #000000;
  border-right: 2px solid #000000;
  border-left: 2px solid #000000;
  /* background-color: #d7ffff; */
}

.inputCourseLessonMainContainer {
  padding: 10px;
}

.courseLessonContentTypeMainContainer {
  display: flex;
  gap: 2em;
}

.courseLessonContentTypeContainer {
  display: flex;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
}

.courseLessonContentTypeOptionContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.showLessonImageContainerImage {
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.showLessonImageContainerLessonName {
  padding-top: 10px;
  font-weight: 700;
  font-size: 16px;
  color: #007c7c;
}

.showLessonImageContainerLessonDuration {
  padding-top: 5px;
  font-size: 14px;
  color: #007c7c;
}

.lessonAccordionLabelContainer {
  display: flex;
  align-items: center;
  gap: 0.5em;
  font-weight: bold;
  font-size: 20px;
}

.lessonAccordionLabelContainer img {
  object-fit: cover;
  width: 20px;
  height: auto;
}

.lessonAccordionIconContainer img {
  width: 20px;
  height: auto;
  margin-right: 1em;
}

.btnContinueWatchVideoContainer {
  border-radius: 5px;
  background-color: #002b73;
  color: #ffffff;
  cursor: pointer;
}

.btnContinueWatchVideoContainerDisabled {
  border-radius: 5px;
  color: #000000;
  cursor: pointer;
}

@media (max-width: 767px) {
  .showLessonImageContainer {
    width: 100%;
  }
}
