.headerComponentMainContainer {
  background-color: white;
  position: fixed;
  padding: 2vw 3vw;
  top: 0;
  right: 0;
  left: 0;
  margin-left: 290px;
  display: flex;
  width: "100%";
  justify-content: space-between;
  z-index: 1001;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
}

.headerComponentSearchContainer {
  background-color: white;
  border-radius: 25px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  width: 30vw;
  height: 40px;
}

.headerComponentSearchContainer img {
  width: 20px;
  height: 20px;
}

.headerComponentSearchContainer input {
  margin-left: 0.5em;
  background-color: transparent;
  border: none;
  width: 100%;
  outline: none;
}

.headerComponentNotificationIcon {
  object-fit: contain;
  cursor: pointer;
  width: 35px;
  height: 35px;
}

.headerComponentAccountActionText {
  user-select: none;
  :hover {
    font-weight: bold;
  }
}

.headerComponentAccountIcon {
  width: 35px;
  height: 35px;
}

.notificationMainContainer {
  background-color: white;
  position: fixed;
  top: 8.5vh;
  right: 7.5vw;
  left: 47.5vw;
  margin-left: 290px;
  border-radius: 10px;
  z-index: 1002;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
}

.notificationHeaderContainer {
  display: flex;
  justify-content: space-between;
  padding: 1vw 2vh;
}

.notificationTitleStyle {
  font-weight: 500;
  font-size: 15px;
}

.notificationHrStyle {
  border: 0.5px solid rgb(216, 216, 216);
}

.notificationBodyContainer {
  text-align: left;
  overflow: scroll;
  max-height: 29vh;
}

.notificationBodyTextContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.5vw 2vh;
}

.eachNotificationContainer {
  cursor: pointer;
}

.notificationTextStyle {
  font-size: 14px;
  width: 77%;
}

.notificationTimeStyle {
  font-size: 12px;
  color: rgb(138, 138, 138);
}

.notificationButtonContainer {
  border: 1px solid #000000;
  margin-left: 1vw;
  margin-bottom: 1vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5px;
}

.notificationButtonLabel {
  font-size: 14px;
  padding: 0.25vh 0.25vw;
}

/* @media (max-width: 767px) {
  .notificationMainContainer {
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin-left: 0px;
    border-radius: 10px;
    z-index: 1002;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }

  .notificationHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1vh 0;
  }

  .notificationBodyContainer {
    text-align: left;
    overflow: scroll;
    max-height: 82.5vh;
  }

  .notificationTitleStyle {
    font-weight: 500;
    font-size: 15px;
    position: relative;
    margin-left: 1em;
  }

  .profileMainContainer {
    background-color: white;
    position: fixed;
    top: 6.5vh;
    right: 4vw;
    left: 60vw;
    margin-left: 0px;
    border-radius: 10px;
    z-index: 1002;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }

  .notificationBodyTextContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 2vh;
  }

  .notificationButtonContainer {
    border: 1px solid #000000;
    margin-left: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 5px;
  }
} */

@media (max-width: 1200px) {
  .notificationMainContainer {
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    margin-left: 0px;
    border-radius: 10px;
    z-index: 1002;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }

  .notificationHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1vh 0;
  }

  .notificationBodyContainer {
    text-align: left;
    overflow: scroll;
    height: 82vh;
    max-height: 100vh;
  }

  .notificationTitleStyle {
    font-weight: 500;
    font-size: 15px;
    position: relative;
    margin-left: 1em;
  }

  .profileMainContainer {
    background-color: white;
    position: fixed;
    top: 6.5vh;
    right: 4vw;
    left: 60vw;
    margin-left: 0px;
    border-radius: 10px;
    z-index: 1002;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }

  .notificationBodyTextContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px 2vh;
  }

  .notificationButtonContainer {
    border: 1px solid #000000;
    margin-left: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 5px;
  }

  .headerDetailContentContainer {
    margin-bottom: -0.5em;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    width: 100%;
    margin: 0 auto;
  }

  .headerDetailContentTitle {
    display: flex;
    justify-content: center;
    width: 100%;
    font-weight: bold;
    color: #ffffff;
    font-size: 23px;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .notificationMainContainer {
    background-color: white;
    position: fixed;
    top: 8.5vh;
    right: 0;
    left: 36vw;
    margin-left: 290px;
    border-radius: 10px;
    z-index: 1002;
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
    width: 30vw;
  }
}
