@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.col {
  padding: 0;
}

.row {
  padding: 0;
  --bs-gutter-x: 0;
}

::-webkit-input-placeholder {
  /* Edge */
  color: #aaaaaa !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aaaaaa !important;
}

::placeholder {
  color: #aaaaaa !important;
}

.modal-dialog {
  max-width: 600px;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-header {
  border-bottom: 0 !important;
  align-items: flex-start;
}

.modal-header .btn-close {
  margin: 0;
}

.modal-footer {
  border-top: 0 !important;
}

:hover {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
input[type="password"]:focus,
.uneditable-input:focus {
  box-shadow: none;
  outline: 0 none;
}

input.date-picker {
  border: 2px solid #00bb99;
  border-radius: 10px;
  height: 50px;
  padding: 0.5em;
  width: 100%;
}

input,
textarea {
  color: black;
}

.swal2-styled.swal2-confirm {
  background-color: #00bb99 !important;
  border-color: #00bb99 !important;
  color: white !important;
  padding: 10px !important;
  min-width: 200px !important;
  border-radius: 40px !important;
}

.swal2-html-container {
  font-weight: bold !important;
}

.swal2-container.swal2-center > .swal2-popup {
  border-radius: 20px !important;
}

.swal2-modal.mentor-popup {
  border: 1px solid #00bb99;
}

/* react-day-picker */
button.rdp-day_selected {
  background-color: black !important;
  border: none !important;
  font-weight: 700 !important;
  color: white !important;
}
/*  */

.greenBtnStyle {
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* :hover {
    background-color: ${({ bgColor }) => (bgColor ? bgColor : "#00BB99")};
    opacity: ${({ isError }) => (isError ? 0.3 : 0.8)};
  } */
}
