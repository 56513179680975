.homePageTitle {
  padding-left: 0;
  font-size: 36px;
  color: #2c65f7;
}

.homeLineContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.homeLatestCoachingContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.homePageLabel {
  padding-left: 0;
  padding-top: 0;
  font-size: 18px;
  color: black;
  font-weight: bold;
}

.homeLatestCoachingCard {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  background-color: #2c65f7;
  border-radius: 10px;
  margin-bottom: 1rem;
  color: #ffffff;
}

.homeImageContainerDimmed {
  filter: brightness(0.5);
  position: relative;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

.homeImageContainerDimmed img {
  width: 17.5vw;
  height: auto;
  border-radius: 20px;
  object-fit: contain;
}

.homeImageContainer {
  position: relative;
  height: 15rem;
  margin-bottom: 1.75rem;
  cursor: pointer;
}

.homeImageContainer img {
  width: 17.5vw;
  height: auto;
  border-radius: 20px;
  object-fit: contain;
}

.imageContainerWide {
  position: relative;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

.imageContainerWide img {
  width: 36.5vw;
  border-radius: 20px;
  object-fit: contain;
}

.homePageDownloadButton {
  position: absolute;
  bottom: 3rem;
  left: 2.5rem;
  color: white;
  background-color: #4285f4;
  border-radius: 20px;
  border: none;
  width: 9rem;
  padding: 0.25rem 1rem;
  filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
    drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  font-size: 14px;
}

@media (max-width: 767px) {
  .homeLineContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }

  .homeLatestCoachingContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-top: 1em;
  }

  .homeImageContainerDimmed img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: contain;
  }

  .homeImageContainer {
    position: relative;
    height: auto;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }

  .homeImageContainer img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: contain;
  }

  .homePageLabel {
    padding-left: 0;
    padding-top: 0;
    font-size: 18px;
    color: black;
    font-weight: bold;
  }

  .imageContainerWide img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: contain;
  }

  .homePageDownloadButton {
    position: absolute;
    bottom: 5vh;
    left: 5vw;
    color: white;
    background-color: #4285f4;
    border-radius: 20px;
    border: none;
    width: 8rem;
    padding: 0.25rem 1rem;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
      drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .homeLineContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
  }

  .homeImageContainerDimmed {
    filter: brightness(0.5);
    position: relative;
    margin-bottom: 0.25rem;
    cursor: pointer;
  }

  .homeImageContainerDimmed img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: contain;
  }

  .homeImageContainer {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: auto;
    margin-bottom: 0.5rem;
    cursor: pointer;
    width: 100%;
  }

  .homeImageContainer img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: contain;
  }

  .imageContainerWide img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    object-fit: contain;
  }

  .homePageDownloadButton {
    position: absolute;
    bottom: 5vh;
    left: 3vw;
    color: white;
    background-color: #4285f4;
    border-radius: 20px;
    border: none;
    width: 8rem;
    padding: 0.25rem 1rem;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04))
      drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
    font-size: 13px;
  }
}
