.ql-editor {
  height: 250px;
}

.sun-editor .se-btn-primary {
  background-color: #0d4c86;
  border: none;
}

.sun-editor .se-btn-primary:hover {
  background-color: #0d4c86;
}

.se-btn-primary span {
  color: white;
}
