.tableComponentTrTag {
  cursor: pointer;
}

.tableComponentTrTag:hover {
  background-color: #eeeeee;
}

.tableComponentTrTagUserUnassginedRole {
  cursor: pointer;
  background-color: #eeeeee;
}

.tableComponentTrTagUserUnassginedRole:hover {
  background-color: #D7FFFF;
}
