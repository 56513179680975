.loginFormMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  background-color: #ffffff;
}

.loginWelcomeText {
  font-size: 36px;
  font-weight: bold;
  color: #00bb99;
  margin-top: -0.175em;
}

.loginGoogleBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80%;
  border: 2px solid #00bb99;
  background: transparent;
  color: black;
  border-radius: 10px;
  padding: 0.5rem;
  :hover {
    background: transparent;
    border: 2px solid #00bb99;
    color: black;
  }
}

.loginGoogleBtnContainer img {
  object-fit: scale-down;
}

.loginButton {
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: #00bb99;
  border: #00bb99;
  border-radius: 40px;
  width: 40%;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  min-width: 160px;
  min-height: 40px;
  padding: 0.5rem 1rem;
}

@media (max-width: 767px) {
  .loginFormMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    width: 95%;
    height: 100%;
    margin: 0 auto;
    margin-top: 1rem;
    background-color: #ffffff;
  }

  .loginButton {
    font-size: 15px;
    font-weight: bold;
    color: white;
    background: #00bb99;
    border: #00bb99;
    border-radius: 40px;
    width: 40%;
    filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
    min-width: 160px;
    min-height: 40px;
    padding: 0.5rem 1rem;
  }
}
