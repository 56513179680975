.alertComponentMainContainer {
  position: absolute;
  left: 20px;
  right: 20px;
  z-index: 3;
  top: 20px;
}

.alertComponentImage {
  object-fit: scale-down;
}
