.loginVerificationMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 80%;
  height: 100%;
  margin: auto;
}

.loginVerificationLockedIconContainer {
  position: relative;
  height: 150px;
  width: 120px;
}

.loginVerificationLockedIconContainer img {
  object-fit: scale-down;
  height: 150px;
  width: 120px;
}

.loginVerificationOtpContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

@media (max-width: 767px) {
  .loginVerificationLockedIconContainer {
    position: relative;
    height: 110px;
    width: 80px;
  }

  .loginVerificationLockedIconContainer img {
    object-fit: scale-down;
    height: 110px;
    width: 80px;
  }
}
