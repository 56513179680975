.registerFormMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  margin-top: 1rem;
}

.registerWelcomeText {
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  color: #00bb99;
  margin-bottom: 0;
}

.registerResendEmailBtn {
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: #00bb99;
  border: #00bb99;
  border-radius: 40px;
  padding: 10px 16px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  :hover {
    background: #00bb99;
    opacity: 0.8;
  }
}

.registerContactSupportBtn {
  font-size: 18px;
  font-weight: bold;
  color: #00bb99;
  background: transparent;
  border: 1px solid #00bb99;
  border-radius: 40px;
  padding: 10px 16px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  :hover {
    background: transparent;
    opacity: 0.8;
    color: #00bb99;
  }
}

@media (max-width: 767px) {
  .registerFormMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    width: 95%;
    height: 100%;
    margin: 0 auto;
    margin-top: 1rem;
  }
}
