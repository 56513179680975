.videoIconAndNameAndSizeAndCancelBtnContainer {
  display: flex;
  justify-content: space-between;
}

.videoIconAndNameAndSizeContainer {
  display: flex;
  align-items: center;
  gap: 1em;
  padding-bottom: 1em;
}

.videoName {
  font-weight: 600;
  padding-bottom: 0.5em;
}

.videoSize {
  color: #9e9e9e;
  font-size: 14px;
}

.videoUploadProgressContainer {
  height: 15px;
  width: 55vw;
  border-radius: 10px;
  background: #cbcbcb;
  margin-bottom: 0.5em;
}
