.sidebarComponentMainContainer {
  left: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  width: 290px;
  background-color: #2c65f7;
  padding: 15px 30px;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  z-index: 1002;
}

.sidebarComponentEachMenuMainContainerTrue {
  margin-bottom: 30px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 25px;
  background-color: none;
}

.sidebarComponentEachMenuMainContainerFalse {
  margin-bottom: 30px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 25px;
  background: #00f5f5;
}

.sidebarStudentComponentEachMenuMainContainerFalse {
  margin-bottom: 30px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 25px;
  background: #002b73;
}

.sidebarComponentEachMenuContainer {
  display: flex;
  align-items: center;
}

.studentPlatformSidebarComponentMainContainer {
  text-align: left;
  background-color: #fce5cd;
  border: 2px solid #ffac4d;
  border-radius: 5px;
}

.studentPlatformSidebarComponentMainContainer div {
  margin: 0.2em 2em 0.2em 2em;
}

@media (max-width: 1200px) {
  .sidebarComponentMainContainer {
    visibility: hidden;
  }
}
