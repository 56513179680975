.coachingBookMainContainer {
  padding: 30px 5vw;
  background-color: #00dddd;
  min-height: 100vh;
}

.coachingBookLogoCCSContainer {
  padding-top: 2.7vh;
  cursor: pointer;
  display: flex;
}

.coachingBookLogoCCSContainer img {
  object-fit: contain;
  width: 200px;
  height: auto;
}

.coachingBookFlexContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10vh;
}

.coachingBookCenteredBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  padding: 20px 30px;
  width: min(600px, 80vw);
  min-height: 52.5vh;
}

.coachingBookBottomBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coachingBookArrowLeft {
  width: 7px;
  object-fit: contain;
  margin-right: 5px;
  user-select: none;
}

.coachingBookCancelButton {
  user-select: none;
  cursor: pointer;
}

.coachingBookCancelButton:hover {
  font-weight: bold;
}

@media (max-width: 767px) {
  .coachingBookFlexContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8vh;
    padding-bottom: 100px;
  }

  .coachingBookCenteredBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    border-radius: 10px;
    padding: 20px 30px;
    width: min(600px, 90vw);
    min-height: 52.5vh;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .coachingBookFlexContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8vh;
    padding-bottom: 100px;
  }
}