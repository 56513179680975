.date-label-secondary {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: #f4f4f4;
  border-radius: 15.6px;
  padding: 10px;
  width: min-content;
  height: min-content;
  margin: 8px 0;
}

.date-label-secondary-day {
  font-size: 14px;
  font-weight: 700;
  color: black;
  letter-spacing: 0.08em;
}

.date-label-secondary-date {
  font-weight: 700;
  color: black;
}
