@import "https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css";

.custom-container {
  padding: 1vh 0px;
}

.header-container {
  height: calc(17vh - 60px);
}

.calendar-container {
  margin-top: -30px;
}

.rbc-calendar .rbc-month-view {
  border: 0;
}

.rbc-month-header {
  border-bottom: 1px solid #002b73;
}

.rbc-month-header .rbc-header:first-of-type {
  border-top-left-radius: 8px;
}

.rbc-month-header .rbc-header:last-of-type {
  border-top-right-radius: 8px;
}

.rbc-month-view
  .rbc-month-row:last-of-type
  .rbc-row-bg
  .rbc-day-bg:first-of-type {
  border-bottom-left-radius: 8px;
}
.rbc-month-view
  .rbc-month-row:last-of-type
  .rbc-row-bg
  .rbc-day-bg:last-of-type {
  border-bottom-right-radius: 8px;
}

.rbc-row-bg .rbc-today {
  background-color: transparent;
}

.toolbar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 0.5em;
}

.toolbar-left {
  display: flex;
  gap: 30px;
  align-items: center;
}

.toolbar-left button {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
}

.toolbar-left .date-label {
  margin: 0;
  font-size: 26px;
}

.toolbar-right {
  display: flex;
  align-items: center;
  border: 1px solid #002b73;
  border-radius: 8px;
}

.toolbar-right label {
  width: 120px;
  font-size: 16px;
  font-weight: 400;
  padding: 4px 16px;
  text-align: left;
  border-top: unset;
  border-bottom: unset;
  background: transparent;
  border: none;
}

.toolbar-right label:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: unset;
  border-right: 1px solid #002b73;
}
.toolbar-right label {
  border-left: unset;
  border-right: 1px solid #002b73;
}

.toolbar-right label:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-right: unset;
  border-left: unset;
}

.rbc-month-view .rbc-day-bg {
  border-left: 1px solid #002b73;
  border-bottom: 1px solid #002b73;
}
.rbc-month-view .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #002b73;
}

.rbc-month-header .rbc-header {
  border: 1px solid #002b73;
  border-bottom: 0;
  padding: 4px 8px;
  text-align: left;
}
.rbc-row .rbc-date-cell {
  color: #002b73;
  text-align: left;
  padding: 8px;
  padding-bottom: 3px;
}
.rbc-row .rbc-row-segment {
  padding: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.rbc-row .rbc-row-segment .rbc-event {
  border-radius: 4px;
}
.rbc-row .rbc-row-segment .rbc-event.rbc-selected {
  background-color: #002b73;
}

.rbc-row .rbc-row-segment .rbc-event {
  margin: 0 0 4px 0;
}

.rbc-timeslot-group .rbc-day-slot .rbc-event {
  background-color: #002b73;
  height: 3em;
}

.rbc-month-header .rbc-header span {
  text-transform: uppercase;
  border-bottom: 0;
  font-size: 12px;
}

.rbc-month-view .rbc-month-row + .rbc-month-row {
  border-top: 0;
}

.rbc-month-row {
  border-right: 1px solid #002b73;
}

.rbc-date-cell .rbc-button-link {
  font-size: 14px;
  font-weight: 500;
}

.rbc-date-cell.rbc-now .rbc-button-link {
  background-color: #002b73;
  color: white;
  font-weight: 700;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-top: -5px;
}

.close-icon-container {
  cursor: pointer;
}

.rbc-overlay .rbc-event {
  background-color: #002b73;
  border-radius: 4px;
  margin: 0 0 4px 0;
}
.rbc-overlay .rbc-event.rbc-selected {
  background-color: #002b73;
  border-radius: 4px;
  margin: 0 0 4px 0;
}

/* Meeting Details Tabs */
.react-tabs .react-tabs__tab-list {
  border-bottom: 2px solid #dddddd !important;
}

.selected-tab {
  border-bottom: 4px solid #002b73 !important;
}

/* Daily View */
.rbc-events-container .rbc-event {
  background-color: #002b73;
  border: 0;
  border-radius: 4px;
}

.rbc-events-container > *:not(:first-child) {
  /* box-shadow: 3px 0px 10px rgba(0, 0, 0, 0.19); */
}

.rbc-events-container .rbc-event.rbc-selected {
  background-color: #002b73;
  border: 1px solid white;
  border-radius: 4px;
}

.rbc-events-container .rbc-event .rbc-event-label {
  display: none;
}

.rbc-time-gutter .rbc-timeslot-group {
  min-height: 100px;
}

.rbc-time-gutter .rbc-timeslot-group .rbc-time-slot .rbc-label {
  color: #444444;
  font-size: 14px;
  font-weight: 700;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid white !important;
}

.rbc-time-content .rbc-today {
  background-color: transparent;
}

.rbc-day-slot .rbc-timeslot-group {
  min-height: 100px;
}
.rbc-time-header.rbc-overflowing {
  border-right: unset;
}
.rbc-time-header-content {
  border-left: unset;
}

.rbc-time-header-content .rbc-allday-cell {
  display: none;
}
.rbc-header {
  border-bottom: unset;
  text-align: left;
}

.rbc-header + .rbc-header {
  border-left: unset;
}

.rbc-header.rbc-today {
  background: unset;
}

.rbc-header.rbc-today .date-label-secondary {
  background-color: #002b73;
}
.rbc-header.rbc-today .date-label-secondary-day {
  color: white;
}
.rbc-header.rbc-today .date-label-secondary-date {
  color: white;
}

.rbc-time-view .rbc-row {
  height: 100%;
}

.rbc-time-view .rbc-allday-cell {
  height: unset;
}
.rbc-calendar .rbc-time-view {
  border: 0;
}

.rbc-current-time-indicator {
  background-color: #002b73;
  height: 2px;
}

.rbc-agenda-view table.rbc-agenda-table {
  border: none;
}
.rbc-agenda-table thead {
  display: none;
}
.rbc-agenda-time-cell {
  display: none;
}

.rbc-row .rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #002b73;
  height: 3.35em;
}

.agenda-event {
  font-size: 13px;
  color: white;
  background: linear-gradient(0deg, #002b73, #002b73), #ffffff;
  border-radius: 4px;
  padding: 6px 4px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-slot-selection {
  display: none;
}

.floating-menu-container {
  position: fixed !important;
  align-items: flex-end !important;
  right: 5%;
  bottom: 7%;
  z-index: 10;
}

.sc-gswNZR.boiqXA {
  width: 200px;
  margin-bottom: 15px;
}

.floating-child-button {
  background: #ffffff;
  color: #eb008b;
  border: 2px solid #eb008b;
  border-radius: 96px;
  padding: 8px 24px;
  font-size: 14px;
  width: 500px !important;
}

.floating-child-button:hover {
  background: #eb008b;
  color: #ffffff;
  border: 2px solid #eb008b;
  border-radius: 96px;
  padding: 8px 24px;
  font-size: 14px;
  width: 500px !important;
}

.eachWebinarCustomAgendaViewContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}

.registerToWebinarContainer {
  width: 17.5%;
  background: linear-gradient(0deg, #002b73, #002b73), #ffffff;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 0.5em;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registerToWebinarContainer div {
  font-weight: 500;
  /* font-size: 14.5px; */
  color: white;
  padding-top: 0.65em;
  padding-bottom: 0.65em;
}

.showMoreEventStyle {
  width: 80%;
  cursor: pointer;
  font-weight: bold;
  color: #002b73;
}

@media (max-width: 767px) {
  .custom-container {
    padding: 0px;
  }

  .registerToWebinarContainer {
    width: 35%;
    background: linear-gradient(0deg, #002b73, #002b73), #ffffff;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0.5em;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .registerToWebinarContainer div {
    font-weight: 500;
    /* font-size: 14.5px; */
    color: white;
    padding-top: 1em;
    padding-bottom: 1em;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .custom-container {
    padding: 0px;
  }

  .registerToWebinarContainer {
    width: 18%;
    background: linear-gradient(0deg, #002b73, #002b73), #ffffff;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0.5em;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
