.myBookingInnerContainerEmptySession {
  padding: 130px 3vw 30px 3vw;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.myBookingInnerContainerEmptySession img {
  object-fit: scale-down;
  width: 300px;
  height: 250px;
}

.myBookingInnerContainer {
  padding: 130px 3vw 30px 3vw;
  text-align: left;
}

.noDataMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}