.studentCoursesCardContainer {
  margin: 0px 0vw 30px 0vw;
  display: flex;
  gap: 3vw;
  overflow: auto;
}

.courseCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #c4c4c4;
  padding: 2vh 1vw;
  border-radius: 20px;
  cursor: pointer;
}

.buttonAddToMyCourseContainer {
  width: 15vw;
  color: #ffffff;
  margin-top: 2vh;
  padding: 1vh 1vw;
  border-radius: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.courseImageContainer {
  width: 384px;
  height: 254px;
}

.courseImageContainer img {
  width: 384px;
  height: 254px;
  border-radius: 20px;
  object-fit: cover;
}

.courseTitleStyle {
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  line-height: 95%;
}

.courseLengthStyle {
  width: 100%;
  text-align: left;
}

.my-confirm-button {
  background-color: #f3f3f3; /* Green background */
  color: white; /* White text */
  border: none; /* Remove borders */
  padding: 10px 20px; /* Add some padding */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Get elements to line up horizontally */
  font-size: 16px; /* Increase font size */
  margin: 4px 2px; /* Add some margin */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 12px; /* Rounded corners */
}

.my-confirm-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

@media (max-width: 767px) {
  .courseImageContainer {
    width: 100%;
    height: auto;
  }

  .courseImageContainer img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }

  .buttonAddToMyCourseContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    color: #ffffff;
    /* margin-top: 2vh;
    padding: 1vh 1vw; */
    border-radius: 20px;
    font-weight: bold;
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .courseCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #c4c4c4;
    padding: 2vh 1vw;
    border-radius: 20px;
    cursor: pointer;
    background-color: #ffffff;
  }
}
