.loginRegisterContainer {
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  background-color: #00dddd;
}

.ccsLogoLightMainContainer {
  padding: 1rem 2rem;
}

.ccsLogoLightContainer {
  position: relative;
  height: 5rem;
  width: 15rem;
}

.ccsLogoLightContainer img {
  object-fit: scale-down;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: scale-down;
}

.loginRegisterAlertMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  height: 100%;
}
